import { ClassEnum } from "../_enum/form/class.enum";
import { alwaysDeleteArray, ArrayModel } from "../_model/form/array.model";
import { alwaysDeleteInput, InputModel } from "../_model/form/input.model";
import { ElementModel } from "../_model/form/element.model";
import { alwaysDeleteGroup, GroupModel } from "../_model/form/group.model";
import { FormModel } from "../_model/form/form.model";
import { exists, isArray, isDate, isISOString } from "./util.helper";
import { RuleModel } from "../_model/rule/rule.model";
import { environment } from "../../environments/environment";
import { alwaysDeleteSpacer, SpacerModel } from "../_model/form/spacer.model";
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { DataTypeEnum } from "../_enum/form/data-type.enum";
import { RuleService } from "../_service/rule.service";
import { LookupModel } from "../_model/metadata/lookup.model";
import { InputEnum, isDateInput, isFileInput, isMultiInput, isSelectInput, isTextInput, isTimeInput } from "../_enum/form/input.enum";
import { Pattern, PatternEnum } from "../_enum/form/pattern.enum";
import { getLookups, getLookupsKeysValuesWithConstraints, mappingToStandardName } from "./lookup.helper";
import { blocked, maxArray, maxDate, minArray, minDate } from "./validator.helper";
import { RuleConstraint, RuleConstraints } from "../_enum/rule/constraints";
import { Subject } from "rxjs";
import { MiniFormModel } from "../_model/form/mini-form.model";
import { TriggerEnum } from "../_enum/rule/trigger.enum";
import { ActionEnum } from "../_enum/rule/action.enum";
import { RoleFieldsModel } from "../_model/metadata/role-fields.model";
import { MediaModel } from "../_model/media/media.model";
import { FileConfigModel } from "../_model/form/configs/file-config.model";
import { ImageConfigModel } from "../_model/form/configs/image-config.model";

/***************************************************************************/
/* INITIALIZATION HELPERS                                                  */
/***************************************************************************/
/**
 * Prebuilds an entire form with rules applied, element-by-element.
 * Excludes file inputs, those are handled separately.
 */
export function prebuildForm(
  form: FormModel | MiniFormModel, formModel: ArrayModel, ruleService: RuleService,
  lookups: LookupModel[], json: any, indices: number[], edit: boolean, specialEdit: boolean,
  valiDate: Date, roleFields?: RoleFieldsModel, ruleConstraints$?: Subject<RuleConstraint>,
  files?: MediaModel[], images?: MediaModel[]
) {
  for (let section of form.sections) {
    prebuildElement(
      ruleService, section, formModel, form,
      lookups, json, indices, edit, specialEdit,
      valiDate, roleFields, ruleConstraints$,
      files, images
    );
  }
}

/**
 * Prebuilds an individual element on a form
 */
function prebuildElement(
  ruleService: RuleService, element: ElementModel, parent: ElementModel, form: FormModel | MiniFormModel,
  lookups: LookupModel[], json: any, indices: number[], edit: boolean, specialEdit: boolean,
  valiDate: Date, roleFields?: RoleFieldsModel, ruleConstraints$?: Subject<RuleConstraint>,
  files?: MediaModel[], images?: MediaModel[]
) {
  initControl(ruleService, element, parent, form, lookups, json, indices, edit, specialEdit, valiDate, roleFields, ruleConstraints$);
  switch (element?.class) {
    case ClassEnum.Group:
      //prebuild the group's elements
      for (let el of (<GroupModel>element).elements) prebuildElement(
        ruleService, el, element, form,
        lookups, json, indices, edit, specialEdit,
        valiDate, roleFields, ruleConstraints$,
        files, images
      );
      //set required of group
      element.required = (<GroupModel>element).elements.map(el => el?.required).includes(true) || false;
      break;
    case ClassEnum.Array:
      //prebuild the array's elements
      prebuildArray(
        ruleService, <ArrayModel>element, form,
        lookups, json, indices, edit, specialEdit,
        valiDate, roleFields, ruleConstraints$
      );
      //set required of array
      element.required = (<ArrayModel>element).elements.map(el => el?.required).includes(true) || false;
      break;
    case ClassEnum.Input:
      if (isFileInput((<InputModel>element).input) && form instanceof FormModel) {
        let type: 'file' | 'image' = (<InputModel>element).input === InputEnum.File ? 'file' : 'image';
        prebuildFileGroups(
          ruleService, <InputModel>element, form,
          lookups, indices, edit, specialEdit,
          valiDate, type === 'file' ? files : images, type === 'file' ? form.configs?.file : form.configs?.image,
          roleFields, ruleConstraints$,
        );
      }
      break;
    case ClassEnum.Spacer:
    case ClassEnum.Link:
      //No children
      break;
    default:
      environment.error(`[form.helper.ts > prebuildElement()]: Invalid class:`, element?.class);
      break;
  }
}

/**
 * Sets the length of the FormArray in accordance with the data passed in or one empty element
 */
function prebuildArray(
  ruleService: RuleService, model: ArrayModel, form: FormModel | MiniFormModel,
  lookups: LookupModel[], json: any, indices: number[], edit: boolean, specialEdit: boolean,
  valiDate: Date, roleFields?: RoleFieldsModel, ruleConstraints$?: Subject<RuleConstraint>
) {
  let array = mapValueFrom(model.mapping, json, indices);
  if (!edit && model.mapping && json) {
    if (array?.length) {
      if (model.element instanceof GroupModel) array.sort((a, b) => a.Order === b.Order ? 0 : a.Order > b.Order ? 1 : -1);
      while (model.elements.length < array.length)
        add(ruleService, model, form, lookups, json, indices, edit, specialEdit, valiDate, roleFields, ruleConstraints$);
    }
  }
  //if lower than minimum, add more
  if (!edit && exists(model.min))
    while (model.elements.length < model.min)
      add(ruleService, model, form, lookups, json, indices, edit, specialEdit, valiDate, roleFields, ruleConstraints$);
  //if none, add one
  // if (!model.elements.length) {
  //   add(ruleService, model, form, lookups, json, indices, edit, specialEdit, valiDate, roleFields, ruleConstraints$);
  // }
}

/**
 * Increases the indices of the FormArray, forcing elments to generate themselves in accordance
 */
export function add(
  ruleService: RuleService, model: ArrayModel, form: FormModel | MiniFormModel,
  lookups: LookupModel[], json: any, indices: number[], edit: boolean, specialEdit: boolean,
  valiDate: Date, roleFields?: RoleFieldsModel, ruleConstraints$?: Subject<RuleConstraint>
) {
  let _element = edit ? model.element : model.element.clone();
  let index = model.elements.push(_element) - 1;
  prebuildElement(
    ruleService, _element, model, form,
    lookups, json, [...indices, index], edit, specialEdit,
    valiDate, roleFields, ruleConstraints$
  );
}

function prebuildFileGroups(
  ruleService: RuleService, model: InputModel, form: FormModel | MiniFormModel,
  lookups: LookupModel[], indices: number[], edit: boolean, specialEdit: boolean,
  valiDate: Date, files: MediaModel[], config: FileConfigModel | ImageConfigModel,
  roleFields?: RoleFieldsModel, ruleConstraints$?: Subject<RuleConstraint>
) {
  if (files?.length) {
    for (let file of files) {
      let group = buildFileGroup(
        ruleService, form,
        lookups, indices, edit, specialEdit,
        valiDate, file, config,
        roleFields, ruleConstraints$
      );
      //no place for groups at present, we'll stick them there
      if (!model['groups']) model['groups'] = [];
      model['groups'].push(group);
      (<UntypedFormArray>model.control).push(group.control, { emitEvent: false });
    }
  }
}

export function buildFileGroup(
  ruleService: RuleService, form: FormModel | MiniFormModel,
  lookups: LookupModel[], indices: number[], edit: boolean, specialEdit: boolean,
  valiDate: Date, file: MediaModel, config: FileConfigModel | ImageConfigModel,
  roleFields?: RoleFieldsModel, ruleConstraints$?: Subject<RuleConstraint>
): GroupModel {
  let group = new GroupModel({ mapping: 'media' });
  group.control = new UntypedFormGroup({});
  let configKeys = config.getConfigKeys();
  for (let key of configKeys.keys) {
    if (config[key].visible) {
      let element = config[key].clone();
      group.elements.push(element);
      prebuildElement(
        ruleService, element, group, form,
        lookups, file, indices, edit, specialEdit,
        valiDate, roleFields, ruleConstraints$,
        //files, images
      );
    }
  }
  return group;
}

/**
 * Initializes the validation control of the element. Pre-applies the correct validation state to it.
 */
export function initControl(
  ruleService: RuleService, model: ElementModel, parent: ElementModel, form: FormModel | MiniFormModel,
  lookups: LookupModel[], json: any, indices: number[], edit: boolean, specialEdit: boolean,
  valiDate: Date, roleFields?: RoleFieldsModel, ruleConstraints$?: Subject<RuleConstraint>
) {
  //preapply rules
  ruleService.preapplyRules(model, valiDate, indices);
  //build control if not supplied
  buildControl(model, parent, indices);
  if (model?.control) {
    //determine ruleset
    let rules = ruleService.getRules(model);
    //apply validators
    buildValidators(model, model.control, valiDate);
    //set values for inputs
    if (model instanceof InputModel && !isFileInput(model.input)) {
      prefilterLookups(model, lookups);
      setValues(model, json);
      setValue(model, <UntypedFormControl>model.control, json, indices);
    }
    //update validity
    model.control.updateValueAndValidity({ emitEvent: false });
    //disable the control if editing or it is disabled
    if (model.control && model instanceof InputModel && !isFileInput(model.input) && model.input !== InputEnum.Map) {
      if (edit || (!specialEdit && (model.disable || model.readOnly))) model.control.disable({ emitEvent: false });
      if (roleFields && !model.control.disabled) {
        if ((
          //Room field permissions
          model.mapping.startsWith('Rooms[].') &&
          !roleFields.PropertyRooms.includes('allfields') &&
          !roleFields.PropertyRooms.includes(mappingToStandardName(model.mapping).toLowerCase())
        ) || (
          //Media field permissions
          parent?.mapping === 'media' &&
          !roleFields.Media.includes('allfields') &&
          !roleFields.Media.includes(mappingToStandardName(model.mapping).toLowerCase())
        ) || (
          //Regular field permissions
          !model.mapping.startsWith('Rooms[].') && parent?.mapping !== 'media' &&
          !roleFields.Property.includes('allfields') &&
          !roleFields.Property.includes(mappingToStandardName(model.mapping).toLowerCase())
        )) {
          model.control.disable({ emitEvent: false });
        }
      }
    }
    if (!isFileInput(model['input']) && !isTimeInput(model['input'])) {
      //subscribe to changes in value
      model.subscriptions.push(model.control.valueChanges.subscribe(value => {
        if (ruleConstraints$ && model.mapping && RuleConstraints.includes(mappingToStandardName(model.mapping))) {
          ruleConstraints$.next({
            input: <InputModel>model,
            value: value
          });
        } else if (model instanceof InputModel && (model.control.valid || !exists(value) || (isArray(value) && !value.length) || specialEdit)) {
          if (isDate(value)) {
            if (model.input === InputEnum.Date || model.input === InputEnum.DateTimeRange) {
              //date and dateTimeRange default controls only do the dates without the timestamps
              //avoid regular .toISOString() and .slice() because that would offset by timezone
              let year = value.getFullYear();
              let month: number | any = value.getMonth() + 1;
              let day: number | any = value.getDate();
              if (month < 10) month = '0' + month;
              if (day < 10) day = '0' + day;
              value = '' +  year + '-' + month + '-' + day;
              if (value?.includes('NaN')) value = null; //block invalid dates
            } else {
              value = value.toISOString();
            }
          } else if (exists(value) && model.dataType === DataTypeEnum.number) {
            try {
              value = Number(value);
            } catch {
              value = null;
            }
          } else if (!exists(value) || (isArray(value) && !value.length)) {
            value = null;
          } else if (typeof value === 'string') {
            //remove 'invalid' characters from strings
            let nonAscii = value.replace(/[^ -~À-ÖØ-Ýà-öø-ÿ]/, '');
            if (nonAscii != value) return model.control.setValue(nonAscii);
          }

          //assign value to json
          environment.warn(`${model.label} (${model.mapping}):`, value);
          mapValueTo(model.mapping, value, json, indices);
          cascadeValues(model, form, lookups, json);
        }
        //trigger rule application on value changes
        rules.length && ruleService.triggerRules(rules, valiDate, indices);
      }));
    }
  }
  //listen for rule changes if applicable
  ruleService.ruleChanges[model.mapping] &&
    model.subscriptions.push(ruleService.ruleChanges[model.mapping].subscribe(ruleChange => {
      //check indices to make sure they match up
      if (indices.length === ruleChange.indices.length) {
        for (let i = 0; i < indices.length; i++) {
          if (indices[i] !== ruleChange.indices[i]) return;
        }
        let { rebuildValidators, attachState } = ruleService.applyRule(ruleChange.rule, model, json, valiDate);
        if (model.control) {
          if (rebuildValidators) {
            environment.log(`${model.label} (${model.mapping}): ${ruleChange.rule.action} > ${ruleChange.rule.aValue}`);
            buildValidators(model, model.control, valiDate);
          }
          if (attachState !== null) {
            environment.log(`${attachState ? 'Attaching' : 'Detaching'} ${model.label} (${model.mapping})`);
            attachState ? attachParent(model, parent, indices) : detachParent(model, parent, indices);
          }
          //this is a hack to force-update the view when validation changes, sometimes it just don't
          if (model instanceof InputModel && (rebuildValidators || attachState)) {
            model.control.setValue(mapValueFrom(model.mapping, json, indices), { onlySelf: true });
          }
        }
      }
  }));
}

/**
 * Builds a Form Control, supplies it with data if a json is provided and a mapping
 * to it exists, and attaches it to a parent control if it exists
 */
function buildControl(model: ElementModel, parent?: ElementModel, indices?: number[]) {
  switch (model?.class) {
    case ClassEnum.Input:
      if (isFileInput((<InputModel>model).input)) model.control = new UntypedFormArray([]);
      else model.control = new UntypedFormControl(null);
      break;
    case ClassEnum.Group: model.control = new UntypedFormGroup({}); break;
    case ClassEnum.Array: model.control = new UntypedFormArray([]); break;
    case ClassEnum.Spacer: return null;
    case ClassEnum.Link: return null;
    default:
      environment.error('[form.helper.ts > buildControl()]: Missing class for class: ' + model?.class);
      return null;
  }
  model?.visible && attachParent(model, parent, indices);
}

/**
 * If the control passed in has a parent, attaches it to the parent control
 */
function attachParent(model: ElementModel, parent: ElementModel, indices?: number[]) {
  if (model.mapping && !model.attached && parent?.control) {
    switch (parent.class) {
      case ClassEnum.Group:
        (<UntypedFormGroup>parent.control).addControl(mappingToStandardName(model.mapping), model.control, { emitEvent: false });
        model.attached = true;
        break;
      case ClassEnum.Array:
        let index = model.order || 0;
        if (indices?.length) index = indices[indices.length - 1];
        (<UntypedFormArray>parent.control).insert(index, model.control, { emitEvent: false });
        model.attached = true;
        break;
      default:
        environment.error(`[form.helper.ts > attachParent()]: The parent.model has an invalid class`, parent.class);
        break;
    }
  }
}

/**
 * If the control passed in has a parent, detaches it's validation state from the parent.
 */
function detachParent(model: ElementModel, parent: ElementModel, indices?: number[]) {
  if (model.mapping && model.attached && parent?.control) {
    switch (parent.class) {
      case ClassEnum.Group:
        if (parent.control.get(model.mapping)) (<UntypedFormGroup>parent.control).removeControl(mappingToStandardName(model.mapping), { emitEvent: false });
        model.attached = false;
        break;
      case ClassEnum.Array:
        let index = model.order || 0;
        if (indices?.length) index = indices[indices.length - 1];
        if (exists(index)) (<UntypedFormArray>parent.control).removeAt(index, { emitEvent: false });
        model.attached = false;
        break;
      default:
        environment.error(`[form.helper.ts > detachParent()]: The parent.model has an invalid class`, parent.class);
        break;
    }
  }
}

/**
 * Builds a ValidatorFn[] in accordance with the fields used for validation on the InputModel
 */
export function buildValidators(model: ElementModel, control: AbstractControl, valiDate: Date) {
  let validators: ValidatorFn[] = [];
  if (model.visible) { //only bother validating if visible
    model.required && validators.push(Validators.required);
    switch (model.class) {
      case ClassEnum.Input: //input has the most validation, handled separately
        buildInputValidators(<InputModel>model, valiDate, validators);
        break;
      case ClassEnum.Array:
        if (exists((<ArrayModel>model).min)) {
           if (model.required) validators.push(minArray((<ArrayModel>model).min));
           else validators.push(Validators.minLength((<ArrayModel>model).min));
        } else if (model.required) validators.push(minArray(1));
        if (exists((<ArrayModel>model).max)) {
          if (model.required) validators.push(maxArray((<ArrayModel>model).max));
          else validators.push(Validators.maxLength((<ArrayModel>model).max));
       }
      case ClassEnum.Group:
      case ClassEnum.Spacer:
        //nothing to validate at present
        break;
      default:
        environment.error('[form.helper.ts > buildValidators()]: model has invalid class', model.class);
        break;
    }
  }
  control.setValidators(validators);
}

/**
 * Build the validator functions that run on an input when the data is changed.
 */
function buildInputValidators(input: InputModel, valiDate: Date, validators: ValidatorFn[]) {
  //pattern
  input.pattern && validators.push(Validators.pattern(Pattern[input.pattern]));
  //minimum
  buildInputMinValidator(input, valiDate, validators);
  //maximum
  buildInputMaxValidator(input, valiDate, validators);
  //block list
  input.block.length && validators.push(blocked(input.block));
}

function buildInputMinValidator(input: InputModel, valiDate: Date, validators: ValidatorFn[]) {
  if (exists(input.min)) { //min is contextual based on the input
    if (isFileInput(input.input)) {
      //file inputs are special, they're basically secretly arrays
      if (input.required) validators.push(minArray(input.min));
      else validators.push(Validators.minLength(input.min));
    } else if (isDateInput(input.input) || input.input === InputEnum.Year) {
      //date inputs are special, all date validation must be in relation to today (and also the validation date)
      let _valiDate = new Date(valiDate);
      if (input.input === InputEnum.Year) validators.push(Validators.min(getYearOffset(_valiDate, input.min)));
      else validators.push(minDate(getDateOffset(_valiDate, input.min)));
    } else if (input.dataType === DataTypeEnum.number && !isMultiInput(input.input)) {
      validators.push(Validators.min(input.min));
    } else {
      validators.push(Validators.minLength(input.min));
    }
  }
}

function buildInputMaxValidator(input: InputModel, valiDate: Date, validators: ValidatorFn[]) {
  if (exists(input.max)) { //max is contextual based on the input
    if (isFileInput(input.input)) {
      //file inputs are special, they're basically secretly arrays
      if (input.required) validators.push(maxArray(input.max));
      else validators.push(Validators.maxLength(input.max));
    } else if (isDateInput(input.input) || input.input === InputEnum.Year) {
      //date inputs are special, all date validation must be in relation to today (and also the validation date)
      let _valiDate = new Date(valiDate);
      _valiDate.setHours(23, 59, 59, 999);
      if (input.input === InputEnum.Year) validators.push(Validators.max(getYearOffset(_valiDate, input.max)));
      else validators.push(maxDate(getDateOffset(_valiDate, input.max)));
    } else if (input.dataType === DataTypeEnum.number && !isMultiInput(input.input)) {
      validators.push(Validators.max(input.max));
    } else {
      validators.push(Validators.maxLength(input.max));
    }
  }
}

/**
 * Offsets the year of a date by the offset number and returns that (ex: getYearOffset(12-31-2023, -2): 2021 )
 */
export function getYearOffset(date: Date, offset: number): number {
  return date.getFullYear() + offset;
}

/**
 * Offsets the days of a date by the offset number and returns a new date. (ex: getDateOffset(12-31-2023, 1): 01-01-2024 )
 */
export function getDateOffset(date: Date, offset: number): Date {
  let _date = new Date(date);
  _date.setDate(_date.getDate() + offset);
  return _date;
}

/**
 * Prefilters the lookups for a control on init to save runtime compute for filtering lookups
 */
function prefilterLookups(model: InputModel, lookups: LookupModel[]) {
  if (model.mapping && !model.values.length) {
    model.filteredLookups = getLookups(lookups, model.lookup || model.mapping);
  }
}

/**
 * Initially sets the filteredValues of a model with the lookups list (prefiltered by constraints)
 */
export function setValues(model: InputModel, json: any) {
  model.filteredValues = (model.values.length && model.values) ||
    (model.filteredLookups.length && getLookupsKeysValuesWithConstraints(json, model.filteredLookups, model.lookup || model.mapping)) || [];
}

/**
 * Loads the value from the json if supplied, otherwise loads the default value in
 */
function setValue(model: InputModel, control: UntypedFormControl, json?: any, indices?: number[]) {
  //find the value for the field if it exists
  let value: any = mapValueFrom(model.mapping, json, indices);
  //default value if not found in json
  if (!exists(value) && (model.readOnly || !model.disable)) { //check if there's a default
    value = model.value;
    if (exists(value)) mapValueTo(model.mapping, value, json, indices);
  }
  //load value into control
  if (exists(value)) setControlValue(model, control, value, false);
}

/**
 * Used to set the control value with the value supplied.
 * Handles for converting trickier values to the correct data types.
 */
function setControlValue(model: InputModel, control: UntypedFormControl, value: any, emit: boolean) {
  switch (typeof value) {
    case 'string':
      if (isISOString(value)) { //timezones suck
        if (value.length === 10) {
          value = new Date(value);
          value.setMinutes(value.getTimezoneOffset());
        } else {
          value = new Date(value);
        }
      } else if (isMultiInput(model.input)) {
        value = value.split(',').map(s => s?.trim()); //sometimes these are strings
      }
      break;
    case 'number':
      if (model.pattern === PatternEnum.Currency) { //add extra 0 for visual formatting purposes
        let string = '' + value;
        if (string.includes('.') && string.indexOf('.') === string.length - 2) value = string + '0';
      }
    break;
  }
  control.setValue(value, { onlySelf: !emit, emitEvent: emit });
}

/***************************************************************************/
/* VALUE MAPPING                                                           */
/***************************************************************************/
/**
 * Tries to grab the values for multiple mappings from the json.
 * If the value does not exist, it will not be included in the return array.
 * Does not support mapping from arrays.
 */
export function mapValuesFrom(mappings: string[], json: any) {
  let values = [];
  for (let mapping of mappings) {
    let value = mapValueFrom(mapping, json);
    if (exists(value)) values.push(value);
  }
  return values;
}
/**
 * If a model is provided and has a mapping, tries to grab the value from the json if it exists
 */
export function mapValueFrom(mapping: string, json: any, indices?: number[]): any {
  if (json && mapping) {
    //short circuit for less cpu time
    if (mapping.indexOf('.') === -1) {
      if (mapping.endsWith('[]')) {
        mapping = removeBrackets(mapping); //for arrays, remove the brackets
        if (indices?.length) return exists(json[mapping]?.[indices[indices.length-1]]) ? json[mapping][indices[indices.length-1]] : null;
      }
      return exists(json[mapping]) ? json[mapping] : null;
    }
    let paths: string[] = mapping.split('.');
    let temp: any = json;
    let _indices: number[] = indices?.length ? [...indices] : [];
    for (let i = 0; i < paths.length; i++) {
      if (i === paths.length - 1) { //last path, get value
        if (paths[i].endsWith('[]')) paths[i] = removeBrackets(paths[i]); //for arrays, remove the brackets
        return exists(temp[paths[i]]) ? temp[paths[i]] : null;
      } else { //not last path
        if (paths[i].endsWith('[]')) { //is array
          if (!_indices.length) return null;
          paths[i] = removeBrackets(paths[i]);
          if (temp[paths[i]] && temp[paths[i]].length > _indices[0]) { //has data
            temp = temp[paths[i]][_indices.shift()];
          }
        } else if (temp[paths[i]]) { //not array, but has data
          temp = temp[paths[i]];
        } else { //no data
          return null;
        }
      }
    }
  }
  return null; //no data
}

/**
 * If a model is provided and has a mapping, tries to set the value to the json if it exists
 * or builds the json if it does not
 */
export function mapValueTo(mapping: string, value: any, json: any, indices?: number[]): void {
  if (json && mapping) {
    if (mapping.indexOf('.') === -1) {
      if (mapping.endsWith('[]')) mapping = removeBrackets(mapping); //for arrays, remove the brackets
      json[mapping] = value;
      return;
    }
    let paths: string[] = mapping.split('.');
    let temp: any = json;
    let _indices: number[] = indices?.length ? [...indices] : [];
    for (let i = 0; i < paths.length; i++) {
      if (i === paths.length - 1) { //last path, assign value
        temp[paths[i]] = value;
      } else { //not last path
        if (paths[i].endsWith('[]')) { //is array
          if (!_indices.length) return; //no index, can't access
          paths[i] = removeBrackets(paths[i]);
          if (!temp[paths[i]]) temp[paths[i]] = []; //build the array if it doesn't exist
          let index = _indices.shift();
          //if second to last path, build obj because value is inside unmapped obj
          if (i === paths.length - 2 && !temp[paths[i]][index]) {
            let orderOffset = 0; //determine whether it is 0- or 1-indexed (fucking hell stratus, who uses 1?)
            if (temp[paths[i]].length) orderOffset = Math.min(...temp[paths[i]].map(el => el?.Order)) || 0;
            if (orderOffset > 1) orderOffset = 1; //clamp to max offset of 1, just in case something went wrong
            temp[paths[i]][index] = { Order: index + orderOffset };
          }
          temp = temp[paths[i]][index];
        } else { //is group
          if (!temp[paths[i]]) temp[paths[i]] = {}; //build the object if it doesn't exist
          temp = temp[paths[i]];
        }
      }
    }
  }
}

/**
 * Removes the [] from the end of an array mapping
 */
export function removeBrackets(string: string): string {
  return string.substring(0, string.length - 2);
}

/**
 * When a control's values are lookups-driven we need to clear invalid values out
 */
export function cascadeValues(model: InputModel, form: FormModel | MiniFormModel, lookups: LookupModel[], json: any) {
  if (model.filteredLookups.length) { //lookups driven
    //get the RESO mapping
    let fromMapping: string = mappingToStandardName(model.mapping);
    //find the affected inputs
    let toMappings: string[] = [];
    lookups.filter(l => l.lookupFieldConstraint1 === fromMapping  || l.lookupFieldConstraint2 === fromMapping)
      .forEach(l => !toMappings.includes(l.lookupField) && toMappings.push(l.lookupField));
    let toInputs = getAllInputs(form).filter(input => toMappings.includes(mappingToStandardName(input.mapping)));
    //filter their lookups and set controls accordingly
    for (let input of toInputs) {
      if (input.filteredLookups.length) { //lookups driven
        setValues(input, json);
        if (input.filteredValues.length === 1 && input.control.value !== input.filteredValues[0]?.key && input.required) {
          environment.warn(`Setting only value for ${input.label} (${input.mapping}): ${input.filteredValues[0].key}`);
          input.control.setValue(input.filteredValues[0].key);
        } else if (exists(input.control.value) && !input.filteredValues.map(kv => kv.key).includes(input.control.value)) {
          environment.warn(`Clearing invalid value from ${input.label} (${input.mapping}): ${input.control.value}`);
          input.control.setValue(null);
        }
      }
    }
  }
}

/**
 * Removes a control from a form array at the specified index.
 * Handles for a weird bug in Angular's change detection when it comes to arrays
 */
export function removeFromFormArray(array: UntypedFormArray, index: number) {
  //due to a bug in angular's change detection when dynamically rendering FormArrays,
  //the relevent value needs to be put at the end, then sliced off
  array.setValue(
    array.value.slice(0, index).concat( //slice 0 thru index
      array.value.slice(index + 1), //concat w/ index thru end
    ).concat(array.value[index]), //add to the end
    { emitEvent: false, onlySelf: true }
  );
  (<UntypedFormArray>array).removeAt(array.value.length - 1, { emitEvent: false });
}

/**
 * Autofills all required inputs in the listing with valid junk data, and corrects invalid ones with valid junk.
 */
export function autofill(form: FormModel) {
  let { visible, invisible } = getAllInputsByVisibility(form);
  for (let input of visible) {
    if (
      (input.required || input.control?.invalid) && input.control && ((
        !exists(input.control.value) || (isArray(input.control.value) && !input.control.value.length)
    ) || input.control.invalid)) {
      if (input.filteredValues.length) {
        if (isSelectInput(input.input) && !isMultiInput(input.input)) { //random value
          try {
            let validValues = input.filteredValues.filter(kv => !kv.other?.status || kv.other.status === 'Active');
            input.control.setValue(validValues[Math.floor(Math.random() * validValues.length)].key);
          } catch (e) {
            environment.warn('Failed to autofill from', input.filteredValues);
          }
        } else if (isMultiInput(input.input)) { //multiple random values
          let validValues = input.filteredValues.filter(kv => !kv.other?.status || kv.other.status === 'Active');
          let values = [];
          let amountToPopulate = Math.floor(Math.random() * (input.max || validValues.length)) || 1;
          if (amountToPopulate > validValues.length) amountToPopulate = validValues.length - 1;
          for (let i = 0; i < amountToPopulate; i++) {
            let value = validValues[Math.floor(Math.random() * validValues.length)].key;
            while (values.includes(value)) {
              value = validValues[Math.floor(Math.random() * validValues.length)].key;
            }
            values.push(value);
          }
          input.control.setValue(values);
        }
      } else if (isDateInput(input.input)) { //random date within min/max
        let offset = Math.floor(Math.random() * ((input.max || input.min || 0) - (input.min || 0) + 1)) + (input.min || 0);
        if (!exists(input.max)) offset += Math.floor(Math.random() * 10);
        let date = getDateOffset(new Date(), offset);
        input.control.setValue(date);
      } else if (input.input === InputEnum.Year) { //random year within min/max
        let offset = Math.floor(Math.random() * ((input.max || input.min || 0) - (input.min || 0) + 1)) + (input.min || 0);
        let year = new Date().getFullYear() + offset;
        input.control.setValue(year);
      } else if (input.dataType === DataTypeEnum.boolean) {
        input.control.setValue(Math.random() < 0.5 ? true : false);
      } else if (isTextInput(input.input)) { //yay, free-form validation
        if (input.dataType === DataTypeEnum.number) { //figure out which based on pattern
          if (!input.pattern || input.pattern === PatternEnum.Number) {
            input.control.setValue((Math.random() * ((input.max || input.min || 1000000) - (input.min || 0) + 1) + (input.min || 1)).toFixed(Math.random() * 5));
          } else if (input.pattern === PatternEnum.Integer) {
            input.control.setValue(Math.floor(Math.random() * ((input.max || input.min || 1000000) - (input.min || 0)) + (input.min || 0)));
          } else if (input.pattern === PatternEnum.Currency || input.pattern === PatternEnum.Decimal2) {
            input.control.setValue((Math.random() * ((input.max || input.min || 1000000) - (input.min || 0) + 1) + (input.min || 1)).toFixed(2));
          } else if (input.pattern === PatternEnum.Decimal1) {
            input.control.setValue((Math.random() * ((input.max || input.min || 1000000) - (input.min || 0) + 1) + (input.min || 1)).toFixed(1));
          } else if (input.pattern === PatternEnum.Decimal3) {
            input.control.setValue((Math.random() * ((input.max || input.min || 1000000) - (input.min || 0) + 1) + (input.min || 1)).toFixed(3));
          } else {
            //shouldn't be here, but technically currently possible
            input.control.setValue(1);
          }
        } else { //random string I guess
          let charsToPopulate = Math.floor(Math.random() * ((input.max || 25) - (input.min || 0) + 1) + (input.min || 0)) || 1;
          //realistically no validation here
          if (!input.pattern || input.pattern === PatternEnum.Alpha) {
            let chars = '0123456789 ABCDEFG HIJKLMNOP QRS TUV WX YZ abcdefg hijklmnop qrs tuv wx yz';
            let string = '';
            for (let i = 0; i < charsToPopulate; i++) {
              string += chars[Math.floor(Math.random() * chars.length)];
            }
            input.control.setValue(string);
          //oh boy, numbers but they're strings
          } else if (input.pattern === PatternEnum.Integer) {
            let chars = '0123456789';
            let string = '';
            for (let i = 0; i < charsToPopulate; i++) {
              string += chars[Math.floor(Math.random() * chars.length)];
            }
            input.control.setValue(string);
          } else if (input.pattern === PatternEnum.Number) {
            let chars = '0123456789';
            let string = '';
            let decimaled = false;
            for (let i = 0; i < charsToPopulate; i++) {
              if (!decimaled && i >= (charsToPopulate * 0.666) && Math.random() > 0.5) {
                string += '.';
                decimaled = true;
              } else {
                string += chars[Math.floor(Math.random() * chars.length)];
              }
            }
            input.control.setValue(string);
          } else if (input.pattern === PatternEnum.Currency || input.pattern === PatternEnum.Decimal2) {
            let chars = '0123456789';
            let string = '';
            for (let i = 0; i < charsToPopulate; i++) {
              if (i === charsToPopulate - 3) {
                string += '.';
              } else {
                string += chars[Math.floor(Math.random() * chars.length)];
              }
            }
            input.control.setValue(string);
          } else if (input.pattern === PatternEnum.Decimal1) {
            let chars = '0123456789';
            let string = '';
            for (let i = 0; i < charsToPopulate; i++) {
              if (i === charsToPopulate - 2) {
                string += '.';
              } else {
                string += chars[Math.floor(Math.random() * chars.length)];
              }
            }
            input.control.setValue(string);
          } else if (input.pattern == PatternEnum.Decimal3) {
            let chars = '0123456789';
            let string = '';
            for (let i = 0; i < charsToPopulate; i++) {
              if (i === charsToPopulate - 4) {
                string += '.';
              } else {
                string += chars[Math.floor(Math.random() * chars.length)];
              }
            }
            input.control.setValue(string);
          //some hard-coded stuff
          } else if (input.pattern === PatternEnum.Phone) {
            input.control.setValue('555-555-5555');
          } else if (input.pattern === PatternEnum.Email) {
            input.control.setValue('email@example.com');
          } else if (input.pattern === PatternEnum.Url) {
            input.control.setValue('https://www.example.com');
          } else if (input.pattern === PatternEnum.Zip) {
            input.control.setValue(Math.random() > 0.5 ? 'A1A1A1' : '00000' );
          } else if (input.pattern === PatternEnum.Zip_CA) {
            input.control.setValue('A1A1A1');
          } else if (input.pattern === PatternEnum.Zip_US) {
            input.control.setValue('00000');
          }
        }
      }
    }
  }
}

/***************************************************************************/
/* FORM EDITING                                                            */
/***************************************************************************/
/**
 * Removes an element from the form and cleans up any rules associated with it
 */
export function removeElement(form: FormModel | MiniFormModel, parentModel: ElementModel, element: ElementModel) {
  //remove rules containing the model's mapping
  removeElementRules(form, element);
  //remove element from parent
  removeElementFromParent(parentModel, element);
}

/**
 * Removes an element from it's parent. Does not remove associated rules.
 */
export function removeElementFromParent(parentModel: ElementModel, element: ElementModel) {
  switch (parentModel.class) {
    case ClassEnum.Array:
      (<ArrayModel>parentModel).element = null;
      (<ArrayModel>parentModel).elements = [];
      break;
    case ClassEnum.Group:
      (<ArrayModel>parentModel).elements.splice(element.order, 1);
      for (let i = 0; i < (<ArrayModel>parentModel).elements.length; i++) {
        (<ArrayModel>parentModel).elements[i].order = i;
      }
      break;
    default:
      environment.error(`[form.helper.ts > removeElement()]: The parent model to remove from has an invalid class`, parentModel);
      break;
  }
}

/**
 * Removes the rules of the element and any of it's children, recursively
 */
function removeElementRules(form: FormModel | MiniFormModel, element: ElementModel) {
  //remove element rules
  removeRules(form, element.mapping);
  //remove children rules
  switch (element.class) {
    case ClassEnum.Array:
      let _element = (<ArrayModel>element).element;
      if (_element) {
        removeRules(form, _element.mapping);
      }
      break;
    case ClassEnum.Group:
      let elements = (<GroupModel>element).elements;
      if (elements.length) {
        for (let _element of elements) {
          removeElementRules(form, _element);
        }
      }
      break;
    case ClassEnum.Spacer:
    case ClassEnum.Input:
      break;
    default:
      environment.error(`[form.helper.ts > removeElementRules()]: Element class is invalid`, element);
      break;
  }
}

/**
 * Removes any rules associated with a mapping
 */
function removeRules(form: FormModel | MiniFormModel, mapping: string) {
  let allElements = getAllElements(form).filter(el => el.mapping === mapping);
  if (allElements.length === 1) form.rules = form.rules.filter(rule => rule.to !== mapping && rule.from !== mapping);
}

/**
 * Removes a section from the form at the index specified
 */
export function removeSection(form: FormModel, index: number) {
  let section = form.sections.splice(index, 1);
  form.sections.forEach((_section, _index) => {
    _section.order = _index;
    _section.mapping = _index.toString();
  });
  for (let element of section[0].elements) {
    removeElementRules(form, element);
  }
  removeSectionRules(form, index);
}

/**
 * Removes a section's rules and adjusts the rules in accordance with the new order of the sections
 */
function removeSectionRules(form: FormModel, oldIndex: number) {
  //remove rules related to oldIndex
  removeRules(form, oldIndex.toString());
  //adjust all indexes > oldIndex downward
  let oldIndexes = form.sections.filter(section => section.order >= oldIndex).map(section => (section.order + 1).toString());
  form.rules.forEach(rule => {
    //looks gross, but as the oldIndexes are guaranteed to be string
    //representations of numbers, should work without errors
    if (oldIndexes.includes(rule.to)) {
      rule.to = (Number(rule.to) - 1).toString();
    }
    if (oldIndexes.includes(<string>rule.from)) { //sections rules cannot be multiple, string cast is fine
      rule.from = (Number(rule.from) - 1).toString();
    }
  })
}

/**
 * Moves the section at the specified index by the adjust number
 */
export function reorderSection(form: FormModel, index: number, adjust: number) {
  form.sections.splice(index + adjust, 0, form.sections.splice(index, 1)[0]);
  form.sections.forEach((section, _index) => {
    section.order = _index;
    section.mapping = _index.toString();
  })
  remapSectionRules(form.rules, index, index + adjust);
}

/**
 * Re-attaches rules to the appropriate section when sections are re-ordered
 */
function remapSectionRules(rules: RuleModel[], oldIndex: number, newIndex: number) {
  rules.forEach(rule => {
    //old becomes new, new becomes old
    if (rule.to === oldIndex.toString()) rule.to = newIndex.toString();
    else if (rule.to === newIndex.toString()) rule.to = oldIndex.toString();
    if (rule.isFrom(oldIndex.toString())) rule.from = newIndex.toString();
    else if (rule.isFrom(newIndex.toString())) rule.from = oldIndex.toString();
  });
}

/**
 * For use when an input is generated from a field.
 * Removes the old rules and creates new ones based off of the input.
 */
export function regenerateInputRules(form: FormModel | MiniFormModel, input: InputModel) {
  //remove old rules
  form.rules = form.rules.filter(rule =>
    !(rule.to === input.mapping && (
      rule.action === ActionEnum.Max ||
      rule.action === ActionEnum.Pattern
    ))
  );
  //create new rules
  //values
  if (input.dataType === DataTypeEnum.boolean) {
    input.values = [{ key: true, value: 'Yes' }, { key: false, value: 'No' }];
  }
  //max
  if (input.max) {
    form.rules.push(new RuleModel({
      from: input.mapping,
      to: input.mapping,
      trigger: TriggerEnum.Init,
      action: ActionEnum.Max,
      aValue: input.max
    }));
  }
  //pattern
  if (input.pattern) {
    form.rules.push(new RuleModel({
      from: input.mapping,
      to: input.mapping,
      trigger: TriggerEnum.Init,
      action: ActionEnum.Pattern,
      aValue: input.pattern
    }));
  }
}

/**
 * Finds all of the direct parents of an element (potentially multiple because duplicates can exist)
 */
export function findElementParents(form: FormModel | MiniFormModel, element: ElementModel): ElementModel[] {
  let parentModels: ElementModel[] = [];
  for (let section of form.sections) {
    _findElementParents(section, element, parentModels);
  }
  return parentModels;
}

/**
 * The actual findElementParents()
 */
function _findElementParents(parent: ElementModel, element: ElementModel, parentModels: ElementModel[]) {
  switch(parent.class) {
    case ClassEnum.Array:
      if ((<ArrayModel>parent).element?.mapping === element.mapping) {
        parentModels.push(parent);
      } else if ((<ArrayModel>parent).element?.class === ClassEnum.Array || (<ArrayModel>parent).element?.class === ClassEnum.Group) {
        _findElementParents((<ArrayModel>parent).element, element, parentModels);
      }
      break;
    case ClassEnum.Group:
      for (let el of (<GroupModel>parent).elements) {
        if (el.mapping === element.mapping) {
          parentModels.push(parent);
          break;
        } else if (el.class === ClassEnum.Array || el.class === ClassEnum.Group) {
          _findElementParents(el, element, parentModels);
        }
      }
      break;
    case ClassEnum.Input: //no children
    default:
      break;
  }
}

/***************************************************************************/
/* DATA COMPACTION                                                         */
/***************************************************************************/
/**
 * Removes any unused or default properties from a form to save on data storage/transmission time.
 * Also removes recursive references so that the form can be JSON.stringify()'d.
 */
export function compact(form: FormModel): any {
  let json: any = form.clone();
  // compactConfigs(json);
  compactRules(json.rules);
  //compact form
  for (let section of json.sections) {
    compactElement(section);
  }
  //compact mini-forms
  for (let key in json) {
    if (json[key] instanceof MiniFormModel) {
      compactRules(json[key].rules);
      compactElement(json[key].sections[0]);
    }
  }
  return json;
}

const defaultRule = new RuleModel();
/**
 * Removes any unused/default properties from rules to save on data storage/transmission time
 */
function compactRules(rules: RuleModel[]) {
  for (let rule of rules) {
    for (let constraint of rule.constraints) {
      delete constraint.values;
    }
    for (let property in rule) {
      if (canDelete(rule, property, defaultRule, [], [])) delete rule[property];
    }
  }
}

/**
 * Removes any unused/default properties from form configs to save on data storage/transmission time
 */
function compactConfigs(form: FormModel) {
  //Create Config
  let createKeys = form.configs.create.getConfigKeys();
  // let allInputs = getAllInputs(form);
  for (let key1 of createKeys.keys) {
    for (let key2 of createKeys[key1]) {
      compactElement(form.configs.create[key1][key2]);
    }
  }
  //Image Config
  let imageKeys = form.configs.image.getConfigKeys();
  for (let key of imageKeys.keys) {
    compactElement(form.configs.image[key]);
  }
  //File Config
  let fileKeys = form.configs.file.getConfigKeys();
  for (let key of fileKeys.keys) {
    compactElement(form.configs.file[key]);
  }
}

/**
 * Recursively deletes properties in a Form to reduce the raw size of the data
 */
const defaultGroup = new GroupModel();
const defaultArray = new ArrayModel();
const defaultSpacer = new SpacerModel();
const defaultInput = new InputModel();
const dontDeleteElement = ['class', 'input']; //pretty critical stuff
/**
 * Removes any unused/default properties from form elements to save on data storage/transmission time
 */
function compactElement(element: any) {
  switch (element.class) {
    case ClassEnum.Group: //compact all the elements of the FormGroup
      for (let property in element) {
        if (canDelete(element, property, defaultGroup, dontDeleteElement, alwaysDeleteGroup)) delete element[property];
      }
      if (element.elements) {
        for (let el of element.elements) {
          compactElement(el);
        }
      }
      break;
    case ClassEnum.Array: //compact the array and its one element
      for (let property in element) {
        if (canDelete(element, property, defaultArray, dontDeleteElement, alwaysDeleteArray)) delete element[property];
      }
      if (element.element) {
        compactElement(element.element);
      }
      break;
    case ClassEnum.Spacer: //compact the spacer
      for (let property in element) {
        if (canDelete(element, property, defaultSpacer, dontDeleteElement, alwaysDeleteSpacer)) delete element[property];
      }
      break;
    case ClassEnum.Input: //compact the field
      for (let property in element) {
        if (canDelete(element, property, defaultInput, dontDeleteElement, alwaysDeleteInput)) delete element[property];
      }
      break;
    default: break;
  }
}

/**
 * Returns a boolean indicating whether it is safe or not to delete a property from the element
 */
function canDelete(element: any, property: string, defaultModel: any, dontDelete: string[], alwaysDelete: string[]): boolean {
  return !dontDelete.includes(property) && (
    !exists(element[property]) || element[property] === defaultModel[property] ||
    (isArray(element[property])&& !element[property].length) ||
    alwaysDelete.includes(property)
  )
}

/***************************************************************************/
/* MISC                                                                    */
/***************************************************************************/
/**
 * Un-nests all elements from the form and returns them as a flat array.
 * Excludes elements without a label or mapping.
 */
export function getAllElements(form: FormModel | MiniFormModel): ElementModel[] {
  let elements = [];
  for (let section of form.sections) {
    getElements(section, elements);
  }
  return elements.filter(element => exists(element.label) && exists(element.mapping));
}

/**
 * Adds an element to the elements array then pulls any nested elements out of it recursively
 */
export function getElements(element: ElementModel, elements: ElementModel[]) {
  elements.push(element);
  switch (element.class) {
    case ClassEnum.Array:
      if ((<ArrayModel>element).element) {
        getElements((<ArrayModel>element).element, elements);
      }
      break;
    case ClassEnum.Group:
      for (let el of (<GroupModel>element).elements) {
        getElements(el, elements);
      }
      break;
    case ClassEnum.Input:
    default: break;
  }
}

/**
 * Un-nests all inputs from the form and returns them as a flat array.
 * Excludes inputs without a label or mapping.
 */
export function getAllInputs(form: FormModel | MiniFormModel): InputModel[] {
  let inputs = [];
  for (let section of form.sections) {
    getInputs(section, inputs);
  }
  return inputs.filter(el => exists(el.label) && exists(el.mapping));
}

/**
 * Adds an input to the inputs array then pulls any nested elements out other elements recursively
 */
 export function getInputs(element: ElementModel, inputs: ElementModel[]) {
  element instanceof InputModel && inputs.push(element);
  switch (element.class) {
    case ClassEnum.Array:
      if ((<ArrayModel>element).element) {
        getInputs((<ArrayModel>element).element, inputs);
      }
      break;
    case ClassEnum.Group:
      for (let el of (<GroupModel>element).elements) {
        getInputs(el, inputs);
      }
      break;
    case ClassEnum.Input:
    default: break;
  }
}

/**
 * Un-nests all inputs from the form and returns them as two arrays, visible and invisible.
 * Excludes inputs without a label or mapping.
 * Rules need to be pre-applied to the inputs before-hand to accurately reflect the state of the form.
 */
export function getAllInputsByVisibility(form: FormModel | MiniFormModel): { visible: InputModel[], invisible: InputModel[] } {
  let visibility = { visible: [], invisible: [] }
  for (let section of form.sections) {
    getInputsByVisibility(section, section.visible, visibility);
  }
  visibility.visible = visibility.visible.filter(el => exists(el.label) && exists(el.mapping));
  visibility.invisible = visibility.invisible.filter(el => exists(el.label) && exists(el.mapping));
  return visibility;
}

/**
 * Recurses through the element's structure and determines which array in visibility (if an input) it should be added to.
 */
export function getInputsByVisibility(element: ElementModel, parentVisible: boolean, visibility: { visible: InputModel[], invisible: InputModel[] }) {
  let isVisible = element.visible && parentVisible;
  element instanceof InputModel && visibility[isVisible ? 'visible' : 'invisible'].push(element);
  switch (element.class) {
    case ClassEnum.Array:
      if ((<ArrayModel>element).element) {
        getInputsByVisibility((<ArrayModel>element).element, isVisible, visibility);
      }
      break;
    case ClassEnum.Group:
      for (let el of (<GroupModel>element).elements) {
        getInputsByVisibility(el, isVisible, visibility);
      }
      break;
    case ClassEnum.Input:
    default: break;
  }
}

/**
 * Un-nests all elements from the form and returns them as two arrays, visible and invisible.
 * Excludes elements without a label or mapping.
 * Rules need to be pre-applied to the elements before-hand to accurately reflect the state of the form.
 */
export function getAllElementsByVisibility(form: FormModel | MiniFormModel): { visible: ElementModel[], invisible: ElementModel[] } {
  let visibility = { visible: [], invisible: [] }
  for (let section of form.sections) {
    getElementsByVisibility(section, section.visible, visibility);
  }
  visibility.visible = visibility.visible.filter(el => exists(el.label) && exists(el.mapping));
  visibility.invisible = visibility.invisible.filter(el => exists(el.label) && exists(el.mapping));
  return visibility;
}

export function getElementsByVisibility(element: ElementModel, parentVisible: boolean, visibility: { visible: ElementModel[], invisible: ElementModel[] }) {
  let isVisible = element.visible && parentVisible;
  visibility[isVisible ? 'visible' : 'invisible'].push(element);
  switch (element.class) {
    case ClassEnum.Array:
      if ((<ArrayModel>element).element) {
        getElementsByVisibility((<ArrayModel>element).element, isVisible, visibility);
      }
      break;
    case ClassEnum.Group:
      for (let el of (<GroupModel>element).elements) {
        getElementsByVisibility(el, isVisible, visibility);
      }
      break;
    case ClassEnum.Input:
    default: break;
  }
}
