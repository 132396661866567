import { environment } from "../../environments/environment";
import { KeyValueInterface } from "../_model/form/interface/key-value.interface";
import { LookupModel } from "../_model/metadata/lookup.model";
import { PropertyModel } from "../_model/property/property.model";
import { exists, isArray, normalize } from "./util.helper";
import damerauLevenshtein from 'damerau-levenshtein';

/**
 * Converts an mapping (path to where to store the data) to a RESO standard name
 */
export function mappingToStandardName(mapping: string): string {
  return mapping.substring(mapping.lastIndexOf('.') + 1, mapping.length);
}

/**
 * Filters the lookups list to just the lookups for a particular mapping.
 */
export function getAllLookups(lookups: LookupModel[], mapping: string): LookupModel[] {
  let _mapping = mappingToStandardName(mapping);
  return lookups.filter(lookup => lookup.lookupField === _mapping);
}

/**
 * Filters the lookups list to just the lookups for a particular mapping.
 * Excludes draft lookups.
 */
export function getLookups(lookups: LookupModel[], mapping: string): LookupModel[] {
  let _mapping = mappingToStandardName(mapping);
  return lookups.filter(lookup => lookup.lookupField === _mapping && lookup.lookupStatus !== 'Draft');
}

/**
 * Gets a key/value pair array of lookups for a particular mapping.
 * Excludes draft lookups.
 */
export function getLookupsKeysValues(lookups: LookupModel[], mapping: string): KeyValueInterface[] {
  return getLookups(lookups, mapping).map(lookup => { return {
    key: lookup.lookupValue,
    value: lookup.lookupDisplayName,
    other: { status: lookup.lookupStatus }
  }});
}

/**
 * Gets a key/value pair array of lookups for a particular mapping, filtered by lookup constraint.
 * Excludes draft lookups.
 */
export function getLookupsKeysValuesWithConstraints(json: any, lookups: LookupModel[], mapping: string): KeyValueInterface[] {
  return getLookups(lookups, mapping).filter(lookup => (
      !lookup.lookupFieldConstraint1 || !json || lookup.lookupValue1.includes(json[lookup.lookupFieldConstraint1] || '')
    ) && (
      !lookup.lookupFieldConstraint2 || !json || lookup.lookupValue2.includes(json[lookup.lookupFieldConstraint2] || '')
  )).map(_lookup => { return {
    key: _lookup.lookupValue,
    value: _lookup.lookupDisplayName,
    other: { status: _lookup.lookupStatus }
  }});
}

/**
 * A poor attempt to normalize this shit data we get to RESO standards
 */
export function normalizeListing(listing: PropertyModel, lookups: LookupModel[]) {
  if (listing.StreetName && !listing.StreetSuffix) {
    //if we don't have a StreetSuffix, good chance they stuffed it into the StreetName
    let potentialStreetSuffix = listing.StreetName.split(' ').pop();
    let ssKVs = getLookupsKeysValues(lookups, 'StreetSuffix');
    let realStreetSuffix = match('StreetSuffix', ssKVs, potentialStreetSuffix)?.key;
    if (realStreetSuffix) {
      listing.StreetName = listing.StreetName.substring(0, listing.StreetName.lastIndexOf(' '));
      listing.StreetSuffix = realStreetSuffix;
    }
  }
  //set properties
  let old = {};
  for (let key in listing) {
    if (exists(listing[key])) {
      let kvs = getLookupsKeysValues(lookups, key);
      if (kvs.length) {
        old[key] = listing[key];
        if (typeof listing[key] === 'string' && !listing[key].includes(',')) {
          listing[key] = match(key, kvs, listing[key]);
        } else if (isArray(listing[key])) {
          listing[key] = listing[key].map(v => match(key, kvs, v)).filter(v => !!v);
        } else if (typeof listing[key] === 'string' && listing[key].includes(',')) {
          listing[key] = listing[key].split(',').map(v => match(key, kvs, v)).filter(v => !!v).join(',');
        }
        if (!exists(listing[key]) || (
          isArray(listing[key]) && !listing[key].length
        )) delete listing[key];
      }
    }
  }
}

function match(key: string, kvs: KeyValueInterface[], value: string): KeyValueInterface | null {
  let exactMatch = kvs.find(kv => kv.value.toLowerCase() === value.toLowerCase() || kv.key.toLowerCase() === value.toLowerCase());
  if (exactMatch) {
    environment.log(`[exact match] ${key}: ${value} > ${exactMatch.key} (${exactMatch.value})`);
    return exactMatch.key;
  }
  let normalValue = normalize(value);
  let closeMatch = kvs.find(kv => normalize(kv.value) === normalValue || normalize(kv.key) === normalValue);
  if (closeMatch) {
    environment.log(`[close match] ${key}: ${value} > ${closeMatch.key} (${closeMatch.value})`);
    return closeMatch.key;
  }

  let scored = kvs
    //normalize the value to compare
    .map(kv => ({ key: kv.key, value: kv.value, normalValue: normalize(kv.value) }))
    //score length
    .map(kv => ({ ...kv, length: lengthScore(kv.normalValue, normalValue) }))
    .filter(kv => kv.length > 0.1)
    //score character
    .map(kv => ({ ...kv, character: characterScore(kv.normalValue, normalValue) }))
    .filter(kv => kv.character > 0.1)
    //score sequence
    .map(kv => ({ ...kv, sequence: sequenceScore(kv.normalValue, normalValue) }))
    .filter(kv => kv.sequence > 0.1)
    //score reverse
    .map(kv => ({ ...kv, reverse: kv.sequence === 1 ? 1 : reverseScore(kv.normalValue, normalValue) }))
    .filter(kv => kv.reverse > 0.1)
    //score similarity + steps
    .map(kv => ({ ...kv, ...damerauLevenshtein(kv.normalValue, normalValue) }))
    .filter(kv => kv.similarity > 0.1)
    .filter(kv => kv.steps <= Math.abs(kv.normalValue.length - normalValue.length))
    //weight scores
    .map(kv => (delete kv.normalValue) && ({
      ...kv,
      score: (
        kv.length     * 0.03 +
        kv.character  * 0.25 +
        kv.sequence   * 0.40 +
        kv.reverse    * 0.05 +
        kv.similarity * 0.27
      )
    }))
    .filter(kv => kv.score > 0)
    .sort((a, b) => a.score === b.score ? 0 : b.score > a.score ? 1 : -1);

  if (scored.length && scored[0].score >= 0.8) {
    environment.log(`[${scored[0].score.toFixed(3)} match] ${key}: ${value} > ${scored[0].key} (${scored[0].value})`, scored.slice(0, 3));
    return scored[0].key;
  }

  environment.log(`[  no  match] ${key}: ${value} > null`, scored.slice(0, 3));
  return null;
}

function lengthScore(str1: string, str2: string): number {
  let shortest = str1.length <= str2.length ? str1 : str2;
  let longest = str1.length > str2.length ? str1 : str2;
  return shortest.length > 0 && longest.length > 0 ? shortest.length / longest.length : 0;
}

function characterScore(str1: string, str2: string): number {
  let shortest = str1.length <= str2.length ? str1 : str2;
  let longest = str1.length > str2.length ? str1 : str2;
  let score = 0;
  for (let char of shortest) {
    if (longest.includes(char)) score++;
  }
  return score / shortest.length;
}

function sequenceScore(str1: string, str2: string): number {
  let shortest = str1.length <= str2.length ? str1 : str2;
  let longest = str1.length > str2.length ? str1 : str2;
  let score = 0;
  for (let char of shortest) {
    let index = longest.indexOf(char);
    if (index === -1) return score / shortest.length;
    else {
      score++;
      longest = longest.substring(index);
    }
  }
  return score / shortest.length;
}

function reverseScore(str1: string, str2: string): number {
  return sequenceScore(
    str1.split('').reverse().join(''),
    str2.split('').reverse().join('')
  );
}
