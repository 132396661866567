import { environment } from "../../../environments/environment";
import { DataTypeEnum } from "../../_enum/form/data-type.enum";
import { InputEnum, isDateInput } from "../../_enum/form/input.enum";
import { PatternEnum } from "../../_enum/form/pattern.enum";
import { getLookupsKeysValuesWithConstraints } from "../../_helper/lookup.helper";
import { exists } from "../../_helper/util.helper";
import { BaseModel } from "../base.model";
import { LookupModel } from "./lookup.model";
import { InputModel } from "../form/input.model";

/**
 * A Field model from the dd-dao. Translated from:
 * https://github.com/AMPSystems/dd-dao/blob/develop/src/main/java/us/ampre/dao/field/Field.java
 * and
 * https://github.com/AMPSystems/dd-dao/blob/develop/src/main/java/us/ampre/dao/field/FieldByCustomer.java
 * and combined into one for my convenience.
 */
export class FieldModel extends BaseModel {

  public fieldId: number = null;
  public id: number = null;
  public customerName: string = null;
  public resourceName: string = null;
  public standardName: string = null;
  public displayName: string = null;
  public displayNameFrench: string = null;
  public displayNameSpanish: string = null;
  public definition: string = null;
  public simpleDataType: string = null;
  public maxLength: number = null;
  public suggestedPrecision: number = null;
  public recordId: number = 0;
  public lookupStatus: string = null;
  public lookup: string = null;
  public propertyTypes: string[] = [];
  public collection: string = null;
  public edmDataType: string = null;
  public resoDefined: boolean = false;
  public physical: boolean = false;
  public queryable: boolean = false;
  public cloneField: boolean = true;
  public primaryKey: boolean = false;
  public validationType: string = null;
  public majorChange: boolean = false;
  public trackHistory: boolean = true;
  public inputType: InputEnum = null;
  public deprecated: boolean = null;

  constructor(model?: Partial<FieldModel>) {
    super();
    this.overwrite(model);
  }

  public overwrite(model: Partial<FieldModel>, ...exclude: string[]) {
    super.overwrite(model, 'definition', ...exclude);
    this.definition = exists(model?.definition) && model.definition !== 'definition' ? model.definition : null;
    if (this.inputType?.startsWith('k')) { //correct deprecated stuff
      this.inputType = <InputEnum>this.inputType.slice(1, this.inputType.length);
    }
  }

  /**
   * Converts applicable properties to the InputModel equivalent, as a 'partial' InputModel.
   */
  public toInputModel(): Partial<InputModel> {
    let input: Partial<InputModel> = {}
    if (this.displayName) input.label = this.displayName;
    if (this.standardName) input.mapping = this.standardName;
    if (this.definition) input.hint = this.definition;
    if (this.validationType || this.simpleDataType || this.edmDataType) {
      input.dataType = this.translateDataType();
      input.pattern = this.translatePattern();
    }
    if (this.inputType) input.input = this.inputType;
    if (this.maxLength && this.maxLength !== 0 && !isDateInput(this.inputType)) input.max = this.maxLength;
    if (this.lookup) input.lookup = this.lookup;
    return input;
  }

  /**
   * Attempts to automagically generate a full InputModel, complete with validation.
   */
  public generateInput(json: any, lookups: LookupModel[]): InputModel {
    let input = new InputModel(this.toInputModel());
    input.label = input.label?.replace(' Key', '') || '';
    //interpret input type and validation
    if (input.dataType === DataTypeEnum.Date || this.standardName.endsWith('Date') || this.standardName.endsWith('Timestamp')) { //Date is likely a date
      if (!input.input) input.input = this.standardName.endsWith('Timestamp') ? InputEnum.DateTime : InputEnum.Date;
      input.dataType = DataTypeEnum.Date;
      input.max = null; //clear out the max for dates, RESO max length is just the date string "YYYY-MM-DD"
    } else if (this.standardName.endsWith('Key')) { //Key is likely a search
      if (this.standardName.toLowerCase().endsWith('buyeragentkey')) {
        input.input = InputEnum.SearchBuyerAgent;
      } else if (this.standardName.toLowerCase().endsWith('agentkey')) {
        input.input = InputEnum.SearchAgent;
      } else if (this.standardName.toLowerCase().endsWith('officekey')) {
        input.input = InputEnum.SearchOffice;
      }
    } else if (this.standardName.endsWith('YN') || input.dataType === DataTypeEnum.boolean) {
      //has to be a boolean but may be yes/no
      if (!input.input) input.input = InputEnum.ButtonToggle;
      if (input.dataType === DataTypeEnum.boolean) input.values = [{ key: true, value: 'Yes' }, { key: false, value: 'No' }];
      else input.values = [{ key: 'Yes', value: 'Yes' }, { key: 'No', value: 'No' }];
    } else { //try some others
      let _lookups = getLookupsKeysValuesWithConstraints(json, lookups, this.standardName);
      if (_lookups.length) { //likely a select box, potentially not but most likely
        if (!input.input) input.input = InputEnum.Select;
        input.values = _lookups;
      } else { //who knows, just make it a text box and try to put regex on it
        if (!input.input) input.input = InputEnum.Text;
        //interpret pattern
        if (this.standardName.includes('Phone')) {
          input.pattern = PatternEnum.Phone;
        } else if (this.standardName.includes('Number')) {
          input.pattern = PatternEnum.Number;
        } else if (this.standardName.includes('Url')) {
          input.pattern = PatternEnum.Url;
        } else if (this.standardName.includes('Price') || this.standardName.includes('Fee')) {
          input.pattern = PatternEnum.Currency;
        } else if (this.standardName.includes('Email')) {
          input.pattern = PatternEnum.Email;
        }
      }
    }
    return input;
  }

  private translateDataType(): DataTypeEnum {
    let dt: DataTypeEnum = null;
    dt = this.validationToDataType();
    if (!dt) dt = this.simpleToDataType();
    if (!dt) dt = this.edmToDataType();
    return dt;
  }

  private validationToDataType(): DataTypeEnum {
    switch (this.validationType) { //if you're wondering why most of these are empty, it's because fields suck for validation
      case 'Boolean':             return DataTypeEnum.boolean;
      case 'Date':
      case 'Timestamp':           return DataTypeEnum.Date;
      case 'Number':
      case 'DECIMAL':
      case 'Integer':
      case 'INTEGER':             return DataTypeEnum.number;
      case 'String List':
      case 'String List, Single':
      case 'String':
      case 'String List, Multi':
      case 'Collection':
      case 'Year':
      case 'Media':
      case '<n/a>':
      case null:                  return null;
      default:
        environment.warn('[field.model.ts > validationToDataType()]: Missing case for: ' + this.validationType);
        return null;
    }
  }

  private simpleToDataType(): DataTypeEnum {
    switch (this.simpleDataType) {
      case 'Boolean':             return DataTypeEnum.boolean;
      case 'Date':
      case 'Timestamp':           return DataTypeEnum.Date;
      case 'Number':
      case 'String List, Single':
      case 'String':
      case 'String List, Multi':
      case 'Collection':
      case 'Media':
      case null:                  return null;
      default:
        environment.warn('[field.model.ts > simpleToDataType()]: Missing case for: ' + this.simpleDataType);
        return null;
    }
  }

  private edmToDataType(): DataTypeEnum {
    switch (this.edmDataType) {
      case 'Edm.Boolean':        return DataTypeEnum.boolean;
      case 'Edm.Date':
      case 'Edm.DateTimeOffset': return DataTypeEnum.Date;
      case 'Edm.Double':
      case 'Edm.String':
      case 'Edm.Int32':
      case 'Collection':
      case '<n/a>':
      case null:                 return null;
      default:
        environment.warn('[field.model.ts > edmToDataType()]: Missing case for: ' + this.edmDataType);
        return null;
    }
  }

  private translatePattern(): PatternEnum {
    let p: PatternEnum = null;
    p = this.validationToPattern();
    if (!p) p = this.simpleToPattern();
    if (!p) p = this.edmToPattern();
    return p;
  }

  private validationToPattern(): PatternEnum {
    switch (this.validationType) { //if you're wondering why most of these are empty, it's because fields suck for vaidation
      case 'Number':
      case 'DECIMAL':             return PatternEnum.Number;
      case 'Integer':
      case 'INTEGER':
      case 'Year':                return PatternEnum.Integer;
      case 'String List':
      case 'String List, Single':
      case 'String':
      case 'String List, Multi':
      case 'Boolean':
      case 'Date':
      case 'Timestamp':
      case 'Collection':
      case 'Media':
      case null:                  return null;
      default:
        environment.warn('[field.model.ts > validationToPattern()]: Missing case for: ' + this.validationType);
        return null;
    }
  }

  private simpleToPattern(): PatternEnum {
    switch (this.simpleDataType) {
      case 'Number':              return PatternEnum.Number;
      case 'String List, Single':
      case 'String':
      case 'String List, Multi':
      case 'Boolean':
      case 'Date':
      case 'Timestamp':
      case 'Collection':
      case 'Media':
      case null:                  return null;
      default:
        environment.warn('[field.model.ts > simpleToPattern()]: Missing case for: ' + this.simpleDataType);
        return null;
    }
  }

  private edmToPattern(): PatternEnum {
    switch (this.edmDataType) {
      case 'Edm.Double':         return PatternEnum.Number;
      case 'Edm.Int32':          return PatternEnum.Integer;
      case 'Edm.String':
      case 'Edm.Boolean':
      case 'Edm.Date':
      case 'Edm.DateTimeOffset':
      case 'Collection':
      case '<n/a>':
      case null:                 return null;
      default:
        environment.warn('[field.model.ts > edmToPattern()]: Missing case for: ' + this.edmDataType);
        return null;
    }
  }

  public toServerModel(): any {
    let model = this.clone();
    if (!model.definition) model.definition = model.standardName;
    model.propertyTypes = model.propertyTypes.length ? <any>model.propertyTypes.toString() : null;
    return model;
  }
}
