import { BaseEnvironment, EnvironmentEnum } from "./environment.base";

export const environment = new BaseEnvironment({
  env: EnvironmentEnum.DEV,
  production: false,
  logLevel: 'All',
  apiUrl: 'api.dev.ampre.ca/api',
  // apiUrl: '/api',
  publicApiUrl: 'public.dev.ampre.ca',
  // publicApiUrl: '/public-api',
  shapeApiUrl: 'community.dev.ampre.ca',
  // shapeApiUrl: '/shape-api',
  pdfApiUrl: 'pdf.dev.ampre.ca',
  // pdfApiUrl: '/pdf-api',
  dataMappingUrl: 'data-mapping.dev.ampre.ca',
  // dataMappingUrl: 'localhost:8500',
  loginRoute: 'admin',
  disableAdminLogin: false,
  confirmAction: false,
});
